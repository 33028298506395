<template>
  <layout page="teacher-list">
    <page-title icon="lni lni-user" :breadcrumb="breadcrumb">Professores</page-title>
    <div class="card table">
      <header class="card-header">
        <p class="card-header-title">
          <span>Professores</span>
        </p>
        <div class="card-header-icon" aria-label="novo professor">
          <router-link class="button is-primary" :to="b.path">
            <i class="lni lni-plus mr-1"></i> <span>Novo Professor</span>
          </router-link>
        </div>
      </header>
      <div class="card-content">
        <div v-if="teachers.length > 0" class="table-container">
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Email</th>
                <th>Celular</th>
                <td>Ações</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(teacher, index) in teachers" :key="teacher.id">
                <td>{{ teacher.name }}</td>
                <td>{{ teacher.email }}</td>
                <td>
                  <input style="display: none" v-model="teacher.phone" v-mask="'(##) # #### ####'" />
                  {{ teacher.phone }}
                </td>
                <td>
                  <router-link class="button is-primary is-small mr-1" :to="`/admin/professores/${teacher.id}/editar`" title="Editar Professor">
                    <i class="lni lni-pencil-alt"></i>
                  </router-link>
                  <button class="button is-danger is-small" title="Excluir Professor" @click="excluir(index, teacher.id, teacher.name)">
                    <i class="lni lni-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <p class="subtitle has-text-centered my-5">
            <i class="lni lni-information"></i> Nenhum professor no sistema
          </p>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import api from '@/api'
import { mask } from 'vue-the-mask'
import Swal from 'sweetalert2'
import { DASHBOARD, TEACHER_LIST, TEACHER_FORM } from '@/config/breadcrumb/admin'
import Layout from '@/layout/admin/Layout'
import PageTitle from '@/components/admin/PageTitle'

export default {
  name: 'TeacherList',
  directives: {
    mask
  },
  components: {
    Layout,
    PageTitle
  },
  created () {
    api.get('/teachers').then(res => {
      this.teachers = res.data
    })
  },
  data () {
    return {
      teachers: []
    }
  },
  computed: {
    breadcrumb () {
      return [DASHBOARD, { ...TEACHER_LIST, isActive: true }]
    },
    b () {
      return TEACHER_FORM
    }
  },
  methods: {
    excluir (index, id, name) {
      Swal.fire({
        title: 'Tem certeza?',
        text: `Você tem certeza que deseja excluir o professor ${name}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar'
      }).then(result => {
        if (result.isConfirmed) {
          api.delete(`/teachers/${id}`).then(res => {
            if (res.status === 200) {
              this.teachers.splice(index, 1)
            }
          })
        }
      })
    }
  }
}
</script>
